import {enUS, esES} from "@mui/material/locale"

export const allLangs = [
    {
        label: "Español",
        value: "es",
        systemValue: esES,
        icon: "/assets/icons/flags/ic_flag_pe.png"
    },
    {
        label: "English",
        value: "en",
        systemValue: enUS,
        icon: "/assets/icons/flags/ic_flag_usa.png"
    }
]

export const defaultLang = allLangs[0]
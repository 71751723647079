import {PATH_DASHBOARD} from "../../../routes/paths"
import Iconify from "../../../components/iconify"

const ICONS = {
    dashboard: <Iconify icon="mdi:bus-stop" width={24} />,
    map: <Iconify icon="material-symbols:map" width={24} />,
    drivers: <Iconify icon="fa:drivers-license-o" width={24} />,
    buses: <Iconify icon="mingcute:bus-2-fill" width={24} />,
    routes: <Iconify icon="mdi:map-marker-path" width={24} />,
    reports: <Iconify icon="zondicons:news-paper" width={24} />,
    settings: <Iconify icon="icon-park-outline:config" width={24} />
}

const navConfig = [
    {
        subheader: "control",
        items: [
            {title: "busStation", path: PATH_DASHBOARD.departure, icon: ICONS.dashboard},
            {title: "map", path: PATH_DASHBOARD.map, icon: ICONS.map}
        ]
    },
    {
        subheader: "management",
        items: [
            {title: "drivers", path: PATH_DASHBOARD.drivers.root, icon: ICONS.drivers},
            {title: "buses", path: PATH_DASHBOARD.buses.root, icon: ICONS.buses},
            {title: "routes", path: PATH_DASHBOARD.routes.root, icon: ICONS.routes},
            {
                title: "reports",
                path: PATH_DASHBOARD.reports.root,
                icon: ICONS.reports,
                children: [
                    {title: "day", path: PATH_DASHBOARD.reports.day},
                    {title: "bus", path: PATH_DASHBOARD.reports.bus},
                ]
            }
        ]
    },
    {
        subheader: "options",
        items: [
            {title: "settings", path: PATH_DASHBOARD.settings, icon: ICONS.settings}
        ]
    }
]

export default navConfig
import {useState} from "react"
import {useNavigate} from "react-router-dom"
import {alpha} from "@mui/material/styles"
import {Avatar, Box, Divider, MenuItem, Stack, Typography} from "@mui/material"
import {useSnackbar} from "../../../components/snackbar"
import MenuPopover from "../../../components/menu-popover"
import {IconButtonAnimate} from "../../../components/animate"
import {useLocales} from "../../../locales"
import {useAuthContext} from "../../../auth/AuthProvider"
import Iconify from "../../../components/iconify";

const OPTIONS = [
    {
        label: "home",
        linkTo: "/"
    },
    {
        label: "profile",
        linkTo: "/"
    },
    {
        label: "settings",
        linkTo: "/"
    }
]

export default function AccountPopover() {
    const navigate = useNavigate()
    const {user, logout} = useAuthContext()
    const {enqueueSnackbar} = useSnackbar()
    const [openPopover, setOpenPopover] = useState(null)
    const {t} = useLocales()

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget)
    }

    const handleClosePopover = () => {
        setOpenPopover(null)
    }

    const handleLogout = async () => {
        try {
            logout()

            setTimeout(() => {
                console.log("redirect to login")
                navigate("/login", {replace: true})
            }, 100)
            handleClosePopover()
        } catch (error) {
            console.error(error)
            enqueueSnackbar("Unable to logout!", {variant: "error"})
        }
    }

    const handleClickItem = (path) => {
        handleClosePopover()
        navigate(path)
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpenPopover}
                sx={{
                    p: 0,
                    ...(openPopover && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
                        }
                    })
                }}
            >
                <Avatar>
                    <Iconify icon="mdi:user-circle" width={48} />
                </Avatar>
            </IconButtonAnimate>

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 200, p: 0}}>
                {/*

                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.displayName}
                    </Typography>

                    <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: "dashed"}}/>

                <Stack sx={{p: 1}}>
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                            {`${t(option.label)}`}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: "dashed"}}/>
                */}

                <MenuItem onClick={handleLogout} sx={{m: 1}}>
                    {`${t("logout")}`}
                </MenuItem>
            </MenuPopover>
        </>
    )
}
export const brandReq = (t) => t("brandReq")
export const brandTrans = (t) => t("brand")
export const bus = (t) => t("bus")
export const busCreatePage = (t) => t("busCreatePage")
export const busCreatedTrans = (t) => t("busCreated")
export const busEditPage = (t) => t("busEditPage")
export const busList = (t) => t("busList")
export const busStation = (t) => t("busStation")
export const busesOnRoute = (t) => t("busesOnRoute")
export const busesTrans = (t) => t("buses")
export const circulationCardTrans = (t) => t("circulationCard")
export const clear = (t) => t("clear")
export const createBusTrans = (t) => t("createBus")
export const createDriverButton = (t) => t("createDriver")
export const createNewBus = (t) => t("createNewBus")
export const createNewDriver = (t) => t("createNewDriver")
export const deleteBus = (t) => t("deleteBus")
export const deleteBusMessage = (t) => t("deleteBusMessage")
export const deleteBusesMessage = (t) => t("deleteBusesMessage")
export const deleteDriver = (t) => t("deleteDriver")
export const deleteDriverMessage = (t) => t("deleteDriverMessage")
export const deleteDriversMessage = (t) => t("deleteDriversMessage")
export const deleteTrans = (t) => t("delete")
export const descriptionTrans = (t) => t("description")
export const disabled= (t) => t("disabled")
export const dni = (t) => t("dni")
export const documentNumberReq = (t) => t("documentNumberRequired")
export const driverCreatePage = (t) => t("driverCreatePage")
export const driverCreatedBar = (t) => t("driverCreated")
export const driverEditPage = (t) => t("editDriver")
export const driverList = (t) => t("driverList")
export const driverTrans = (t) => t("driver")
export const driversTrans = (t) => t("drivers")
export const editBus = (t) => t("editBus")
export const editDriver = (t) => t("editDriver")
export const editTrans = (t) => t("edit")
export const enableDriver = (t) => t("enableDriver")
export const enabled = (t) => t("enabled")
export const errorUpdatingDataBar = (t) => t("errorUpdatingData")
export const hintDNI = (t) => t("documentNumberHint")
export const hintDigits = (t) => t("onlyDigits")
export const hintLetters = (t) => t("onlyLetters")
export const hintPhone = (t) => t("phoneNumberHint")
export const dateReq = (t) => t("dateRequired")
export const hintInputStringMaxSize = (t) => t("inputStringMaxSize")
export const insuranceTrans = (t) => t("insurance")
export const language = (t) => t("language")
export const lastNameReq = (t) => t("lastNameRequired")
export const lastNameTrans = (t) => t("lastName")
export const licenseExpDateTrans = (t) => t("licenseExpDate")
export const list = (t) => t("list")
export const liveTracking= (t) => t("liveTracking")
export const loginTrans = (t) => t("login")
export const mapTrans = (t) => t("map")
export const modelReq = (t) => t("modelReq")
export const modelTrans = (t) => t("model")
export const nameReq = (t) => t("nameRequired")
export const nameTrans = (t) => t("name")
export const newBus = (t) => t("newBus")
export const newDriver = (t) => t("newDriver")
export const noResults = (t) => t("noResults")
export const othersTrans = (t) => t("others")
export const ownerLastNameTrans = (t) => t("ownerLastName")
export const ownerNameTrans = (t) => t("ownerName")
export const phone = (t) => t("phone")
export const phoneNumberReq = (t) => t("phoneNumberRequired")
export const plateReq = (t) => t("plateRequired")
export const plateTrans = (t) => t("plate")
export const registeredDriverBar = (t) => t("registeredDriver")
export const registeredBusTrans = (t) => t("registeredBus")
export const reportByBus = (t) => t("reportByBus")
export const reports = (t) => t("reports")
export const role = (t) => t("role")
export const roleReq = (t) => t("roleRequired")
export const routeTrans = (t) => t("route")
export const routesTrans = (t) => t("routes")
export const saveChanges = (t) => t("saveChanges")
export const saveChangesButton = (t) => t("saveChanges")
export const search = (t) => t("search")
export const searchByBusOptions = (t) => t("searchByBusOptions")
export const searchByDriverOptions = (t) => t("searchByDriverOptions")
export const selectPlateReq = (t) => t("selectPlateRequired")
export const selectRouteReq = (t) => t("selectRouteRequired")
export const settings = (t) => t("settings")
export const unchangedBusDataBar = (t) => t("unchangedBusData")
export const unchangedDriverDataBar = (t) => t("unchangedDriverData")
export const unknow = (t) => t("unknow")
export const updatedBusTrans = (t) => t("updatedBus")
export const updatedDriverBar = (t) => t("updatedDriver")
export const yearProduction = (t) => t("yearProduction")
export const yearProdReq = (t) => t("yearProductionRequired")
export const labelSelectRoute = (t) => t("labelSelectRoute")
export const labelHour = (t) => t("labelHour")
export const labelPlate = (t) => t("labelPlate")
export const labelStatus = (t) => t("labelStatus")
export const labelRoute = (t) => t("labelRoute")

// login messages
export const labelPassword = (t) => t("password")
export const labelEmail = (t) => t("email")
export const signIn = (t) => t("signIn")
export const msgInvalidPass = (t) => t("msgInvalidPass")
export const msgUserNotFound = (t) => t("msgUserNotFound")
export const msgUserTempDisabled = (t) => t("msgUserTempDisabled")
export const msgErrorFound = (t) => t("msgErrorFound")

// reports messages
export const datePicker = (t) => t("date")
export const lapTrs = (t) => t("lap")
export const lapsTrs = (t) => t("laps")
export const searchBtn = (t) => t("searchBtn")
export const day = (t) => t("day")
export const msgReportDay = (t) => t("msgReportDay")
export const downloadBtn = (t) => t("downloadBtn")

// routes messages
export const labelDeleteRoute = (t) => t("deleteRoute")
export const labelNewRoute = (t) => t("newRoute")
export const labelNo = (t) => t("no")
export const labelRoutes = (t) => t("routes")
export const labelUploadControlPoints = (t) => t("uploadControlPoints")
export const labelUploadFrequency = (t) => t("uploadFrequency")
export const labelUploadRoutes = (t) => t("uploadRoutes")
export const labelYes = (t) => t("yes")
export const msgDeleteRoute = (t) => t("msgDeleteRoute")
export const routeCreatePage = (t) => t("routeCreatePage")
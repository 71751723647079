import PropTypes from 'prop-types'
import {Navigate} from 'react-router-dom'
import LoadingScreen from '../components/loading-screen'
import {useAuthContext} from "./AuthProvider"

GuestGuard.propTypes = {
    children: PropTypes.node,
}

export default function GuestGuard({children}) {
    const {isAuthenticated, isInitialized} = useAuthContext()

    if (isAuthenticated) {
        return <Navigate to="/dashboard"/>
    }

    if (!isInitialized) {
        return <LoadingScreen/>
    }

    return <> {children} </>
}

import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {from} from "rxjs";
import {authFirebase} from "../firebase";

export const AuthContext = createContext(null)

AuthProvider.propTypes = {
    children: PropTypes.node
}

export function AuthProvider({children}) {

    const [userSession, setUserSession] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)

    const initAuthListener = useCallback(() => {
        authFirebase.onAuthStateChanged((user) => {
            if (user) {
                setAuthenticated(true)
            } else {
                setAuthenticated(false)
            }

            setInitialized(true)
        })
    },[])

    useEffect(() => {
        initAuthListener()
    }, [initAuthListener])


    const login = useCallback((email, password) =>
        from(authFirebase.signInWithEmailAndPassword(email, password))
    , [])

    const logout = useCallback(() =>
        from(authFirebase.signOut())
    , [])

    const contextOptions = useMemo(() => ({
            user: userSession,
            isInitialized: initialized,
            isAuthenticated: authenticated,
            setUserSession,
            login,
            logout
        }),
        [
            userSession,
            initialized,
            authenticated,
            setUserSession,
            login,
            logout
        ])

    return <AuthContext.Provider value={contextOptions}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (!context) throw new Error('useAuthContext context must be use inside AuthProvider')

    return context
}
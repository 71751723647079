import {Navigate, useRoutes} from "react-router-dom"
import AuthGuard from "../auth/AuthGuard"
import GuestGuard from "../auth/GuestGuard"
import CompactLayout from "../layouts/compact"
import DashboardLayout from "../layouts/dashboard"
import {
    LoginPage,
    Page404,
    PageBuses,
    PageDashboard,
    PageMap,
    PageReports,
    PageRoutes,
    PageSettings,
    PageDrivers,
    PageDriverCreate,
    PageDriverEdit,
    PageBusCreate,
    PageBusEdit,
    PageReportByBus,
    PageRouteCreate
} from "./elements"
import {PATH_DASHBOARD} from "./paths"

export default function Router() {
    return useRoutes([
        {
            path: "/",
            children: [
                {element: <Navigate to="login" replace />, index: true},
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    )
                }
            ]
        },
        {
            path: "/dashboard",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_DASHBOARD.departure} replace />, index: true},
                {path: "departure", element: <PageDashboard />},
                {path: "map", element: <PageMap />},
                {
                    path: "drivers",
                    children: [
                        {element: <PageDrivers />, index: true},
                        {path: "new", element: <PageDriverCreate />},
                        {path: ":id/edit", element: <PageDriverEdit />}
                    ]
                },
                {
                    path: "buses",
                    children: [
                        {element: <PageBuses />, index: true},
                        {path: "new", element: <PageBusCreate />},
                        {path: ":id/edit", element: <PageBusEdit />}
                    ]
                },
                {
                    path: "routes",
                    children: [
                        {element: <PageRoutes />, index: true},
                        {path: "new", element: <PageRouteCreate />}
                    ]
                },
                {
                    path: "reports",
                    children: [
                        {element: <Navigate to="/dashboard/reports/day" replace />, index: true},
                        {path: "day", element: <PageReports />},
                        {path: "bus", element: <PageReportByBus />},
                    ]
                },
                {path: "settings", element: <PageSettings />}
            ]
        },
        {
            element: <CompactLayout />,
            children: [{path: "404", element: <Page404 />}]
        },
        {path: "*", element: <Navigate to="/404" replace />}
    ])
}
import {Stack, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import LoginLayout from "../../layouts/login"
import AuthLoginForm from "./AuthLoginForm"
import {useLocales} from "../../locales"
import Logo from "../../components/logo"
import {useSettingsContext} from "../../components/settings"

export default function Login() {
    const {appName, companyName} = useSettingsContext()
    const {t} = useLocales()
    const theme = useTheme()

    return (
        <LoginLayout>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Logo />
                <Typography variant="h4" color={theme.palette.primary.main}>{`${appName} - ${companyName}`}</Typography>
            </Stack>

            <Stack spacing={2} sx={{mb: 5, position: "relative"}}>
                <Typography variant="h4">{`${t("login")}`}</Typography>
            </Stack>

            <AuthLoginForm />
        </LoginLayout>
    )
}
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import Bus from '../../components/illustrations/Bus';
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot>
      <StyledSection>
        <Bus />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack
          sx={{ width: 1 }}
          spacing={5}
          direction="column"
          jusitfy="center"
          alignItems="stretch"
        >
          {children}
        </Stack>
      </StyledContent>
    </StyledRoot>
  );
}

export function getIconPath() {
  const appName = process.env.REACT_APP_NAME
  const pathBase = "/favicon/"
  let path

  switch (appName) {
    case "Ilo Sur":
      path = `${pathBase}ilosur.ico`
      break
    case "Libertad":
      path = `${pathBase}libertad.ico`
      break
    case "Bahia del Sur":
      path = `${pathBase}bahia.ico`
      break
    default:
      path = `${pathBase}development.ico`
      break
  }
  return path
}
import {Suspense, lazy} from "react"
import LoadingScreen from "../components/loading-screen"

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    )

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")))

export const PageDashboard = Loadable(lazy(() => import("../pages/PageDashboard")))
export const PageMap = Loadable(lazy(() => import("../pages/PageMap")))
export const PageDrivers = Loadable(lazy(() => import("../pages/PageDrivers")))
export const PageDriverCreate = Loadable(lazy(() => import("../pages/PageDriverCreate")))
export const PageDriverEdit = Loadable(lazy(() => import("../pages/PageDriverEdit")))
export const PageBuses = Loadable(lazy(() => import("../pages/PageBuses")))
export const PageBusCreate = Loadable(lazy(() => import("../pages/PageBusCreate")))
export const PageBusEdit = Loadable(lazy(() => import("../pages/PageBusEdit")))
export const PageRoutes = Loadable(lazy(() => import("../pages/PageRoutes")))
export const PageRouteCreate = Loadable(lazy(() => import("../pages/PageRouteCreate")))
export const PageReports = Loadable(lazy(() => import("../pages/PageReports")))
export const PageReportByBus = Loadable(lazy(() => import("../pages/PageReportByBus")))
export const PageSettings = Loadable(lazy(() => import("../pages/PageSettings")))

export const Page404 = Loadable(lazy(() => import("../pages/Page404")))
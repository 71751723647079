import {Helmet} from "react-helmet-async"
import Login from "../sections/auth/Login"
import {useLocales} from "../locales"
import {useSettingsContext} from "../components/settings"
import {loginTrans} from "../utils/translate/translateUtils"

export default function LoginPage() {
    const {t} = useLocales()
    const {appName} = useSettingsContext()
    return (
        <>
            <Helmet>
                <title> {`${loginTrans(t)} | ${appName}`} </title>
            </Helmet>

            <Login />
        </>
    )
}
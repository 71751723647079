import {useTranslation} from "react-i18next"
import localStorageAvailable from "../utils/localStorageAvailable"
import {useSettingsContext} from "../components/settings"
import {allLangs, defaultLang} from "./config-lang"
import {EMPTY_STRING} from "../utils/StringUtils"

export default function useLocales() {
    const {i18n, t: translate} = useTranslation()

    const {onChangeDirectionByLang} = useSettingsContext()

    const storageAvailable = localStorageAvailable()

    const langStorage = storageAvailable ? localStorage.getItem("i18nextLng") : EMPTY_STRING

    const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

    const handleChangeLanguage = (newlang) => {
        i18n.changeLanguage(newlang)
        onChangeDirectionByLang(newlang)
    }

    return {
        onChangeLang: handleChangeLanguage,
        t: (text, options) => translate(text, options),
        currentLang,
        allLangs
    }
}
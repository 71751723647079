const es = {
    all: "Todos",
    allowedTypeFile: "Admite *.jpeg, *.jpg, *.png, *.gif",
    brand: "Marca",
    brandReq: "Debe ser por ejemplo \"Nissan\"",
    bus: "Bus",
    busCreated: "¡Bus creado!",
    busCreatePage: "Bus: Crear nuevo bus",
    busEditPage: "Bus: Editar bus",
    busList: 'Lista de Buses',
    busStation: "Paradero",
    buses: "Buses",
    busesOnRoute: "Buses en ruta",
    cancel: "Cancelar",
    chooseLang: "Elije un idioma",
    circulationCard: "Tarjeta de Circulación",
    circulationCardRequired: "Fecha de vencimiento de tarjeta de circulación es requerida",
    clear: "Limpiar",
    code: "Código",
    controlPoints: "Puntos de control",
    create: "Crear",
    createBus: "Crear Bus",
    createDriver: "Crear Conductor",
    createNewBus: "Crear un nuevo bus",
    createNewDriver: "Crear un nuevo conductor",
    control: "Control",
    date: "Fecha",
    day: "Día",
    delete: "Eliminar",
    deleteBus: "Eliminar bus",
    deleteBusMessage: "¿Está seguro que desea eliminar el bus?",
    deleteBusesMessage: "¿Está seguro que desea eliminar ",
    deleteDriver: "Eliminar conductor",
    deleteDriverMessage: "¿Está seguro que desea eliminar al conductor?",
    deleteDriversMessage: "¿Está seguro que desea eliminar ",
    deleteRoute: "Eliminar ruta",
    demo: "Demo",
    description: "Description",
    disabled: "Deshabilitado",
    dni: "DNI",
    documentNumberHint: "Debe ser 8 dígitos",
    documentNumberRequired: "Debe ser por ejemplo \"70482540\"",
    downloadBtn: "Descargar",
    driver: "Conductor",
    driverCreated: "¡Conductor creado!",
    driverCreatePage: "Conductor: Crear nuevo conductor",
    driverEditPage: "Conductor: Editar conductor",
    driverLicense: "Brevete",
    driverList: "Lista de Conductores",
    drivers: "Conductores",
    edit: "Editar",
    editBus: "Editar bus",
    editDriver: "Editar Conductor",
    email: "Correo Electrónico",
    enabled: "Habilitado",
    enableDriver: "Habilitar conductor",
    errorUpdatingData: "Error al actualizar datos",
    filters: "Filtros",
    forgotPassword: "¿Olvidaste tu contraseña?",
    friday: "Viernes",
    home: "Inicio",
    inputStringMaxSize: "Máximo 150 caracteres",
    insurance: "SOAT",
    dateRequired: "Debe ser por ejemplo \"23/05/2023\"",
    language: "Idioma",
    lap: "Vuelta",
    laps: "Vueltas",
    lastName: "Apellidos",
    lastNameRequired: "Debe ser por ejemplo \"Torres\" o \"Torres Silva\"",
    license: "Licencia",
    licenseExpDate: "Licencia",
    licenseExpDateRequired: "La fecha de caducidad es requerida",
    list: "Lista",
    liveTracking: "Seguimiento",
    login: "Inicio de Sesión",
    logout: "Cerrar Sesión",
    management: "Administración",
    map: "Mapa",
    maxSize: "tamaño maximo de",
    model: "Modelo",
    modelReq: "Debe ser por ejemplo \"Civilian\"",
    monday: "Lunes",
    name: "Nombre",
    nameRequired: "Debe ser por ejemplo \"Carlos\" o \"Carlos Enrique\"",
    newBus: "Nuevo Bus",
    newDriver: "Nuevo Conductor",
    newRoute: "Nueva Ruta",
    no: "No",
    noResults: "Sin Resultados",
    onlyLetters: "Debe ser solo letras",
    options: "Opciones",
    others: "Otros",
    owner: "Propietario",
    ownerLastName: "Apellidos del accionista",
    ownerName: "Nombre del accionista",
    password: "Contraseña",
    penalty: "Penalidad",
    phone: "Celular",
    phoneNumberHint: "Debe ser 9 dígitos",
    phoneNumberRequired: "Debe ser por ejemplo \"953654423\"",
    plate: "Placa",
    plateRequired: "Debe ser por ejemplo \"A2A588\"",
    profile: "Perfil",
    registeredBus: "Bus ya registrado",
    registeredDriver: "Conductor ya registrado",
    reports: "Reportes",
    reportByBus: "Reportes: Por bus",
    role: "Rol",
    roleRequired: "Debe ser por ejemplo \"Chofer\"",
    route: "Ruta",
    routeCreatePage: "Ruta: Crear nueva ruta",
    routeRequired: "La ruta es requerida",
    routes: "Rutas",
    saturday: "Sábado",
    saveChanges: "Guardar Cambios",
    searchBtn: "Buscar",
    search: "Buscar...",
    searchByBusOptions: "Buscar por placa",
    searchByDriverOptions: "Buscar por nombre, placa o numero de celular",
    selectPlateRequired: "Seleccione una placa",
    selectRouteRequired: "Seleccione una ruta",
    selected: "Seleccionados",
    settings: "Ajustes",
    signIn: "Entrar",
    status: "Estado",
    sunday: "Domingo",
    tuesday: "Martes",
    thursday: "Jueves",
    successCreateDriver: "¡Conductor creado!",
    unchangedBusData: "¡Datos de bus sin modificaciones!",
    unchangedDriverData: "¡Datos de conductor sin modificaciones!",
    unknow: "Desconocido",
    updatedBus: "¡Bus actualizado!",
    updatedDriver: "¡Conductor actualizado!",
    updatePhoto: "Actualizar foto",
    uploadControlPoints: "Cargar puntos de control",
    uploadFrequency: "Cargar frecuencias",
    uploadPhoto: "Subir foto",
    uploadRoutes: "Cargar rutas",
    wednesday: "Miercoles",
    users: "Usuarios",
    yearProduction: "Año",
    yearProductionRequired: "Debe ser por ejemplo \"2005\"",
    yes: "Si",
    msgInvalidPass: "Contraseña invalida",
    msgUserNotFound: "Usuario no encontrado",
    msgUserTempDisabled: "Su usuario ha sido temporalmente desabilitado por demasiados intentos fallidos. Contacte a su administrador",
    msgErrorFound: "Ops, hubo un error",
    labelSelectRoute: "Seleccione una ruta",
    labelHour: 'Hora',
    labelPlate: 'Placa',
    labelStatus: 'Estado',
    labelRoute: 'Ruta',
    msgReportDay: "Seleccione el día y la ruta para descargar su reporte",
    msgDeleteRoute: "¿Está seguro que desea eliminar la ruta?",
}

export default es
import {useState} from "react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {Alert, IconButton, InputAdornment, Link, Stack} from "@mui/material"
import {LoadingButton} from "@mui/lab"
import Iconify from "../../components/iconify"
import FormProvider, {RHFTextField} from "../../components/hook-form"
import {useLocales} from "../../locales"
import {useAuthContext} from "../../auth/AuthProvider";
import {
    labelEmail, labelPassword,
    msgErrorFound,
    msgInvalidPass,
    msgUserNotFound,
    msgUserTempDisabled,
    signIn
} from "../../utils/translate/translateUtils";

export default function AuthLoginForm() {
    const {login} = useAuthContext()
    const {t} = useLocales()

    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required("Email is required").email("Email must be a valid email address"),
        password: Yup.string().required("Password is required")
    })

    const defaultValues = {
        email: "",
        password: ""
    }

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues
    })

    const {
        // reset,
        setError,
        handleSubmit,
        formState: {errors}
    } = methods

    const onLogin = ({email, password}) => {
        setLoading(true)
        login(email, password).subscribe({
            next: _ => {
                setLoading(false)
            },
            error: err => {
                let errorMessage;
                switch (err.code) {
                    case 'auth/wrong-password':
                        errorMessage = msgInvalidPass(t)
                        break
                    case 'auth/user-not-found':
                        errorMessage = msgUserNotFound(t)
                        break
                    case 'auth/too-many-requests':
                        errorMessage = msgUserTempDisabled(t)
                        break
                    default :
                        errorMessage = msgErrorFound()
                }

                setLoading(false)
                setError('login', {message: errorMessage})
            }
        })
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onLogin)}>
            <Stack spacing={3} sx={{mb: 4}}>
                {errors.login && !loading && <Alert severity="error">{errors.login.message}</Alert>}

                <RHFTextField name="email" label={labelEmail(t)}/>

                <RHFTextField
                    name="password"
                    label={labelPassword(t)}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>

            {false && <Stack alignItems="flex-end" sx={{my: 2}}>
                <Link variant="body2" color="inherit" underline="always">
                    {`${t("forgotPassword")}`}
                </Link>
            </Stack>}

            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                    bgcolor: "text.primary",
                    color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
                    "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800")
                    }
                }}
            >
                {signIn(t)}
            </LoadingButton>
        </FormProvider>
    )
}
function path(root, sublink) {
    return `${root}${sublink}`
}

const ROOTS_DASHBOARD = "/dashboard"

export const PATH_AUTH = {
    login: "/login"
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    departure: path(ROOTS_DASHBOARD, "/departure"),
    map: path(ROOTS_DASHBOARD, "/map"),
    drivers: {
        root: path(ROOTS_DASHBOARD, "/drivers"),
        new: path(ROOTS_DASHBOARD, "/drivers/new"),
        edit: (id) => path(ROOTS_DASHBOARD, `/drivers/${id}/edit`)
    },
    buses: {
        root: path(ROOTS_DASHBOARD, "/buses"),
        new: path(ROOTS_DASHBOARD, "/buses/new"),
        edit: (id) => path(ROOTS_DASHBOARD, `/buses/${id}/edit`)
    },
    routes: {
        root: path(ROOTS_DASHBOARD, "/routes"),
        new: path(ROOTS_DASHBOARD, "/routes/new"),
    },
    reports: {
        root: path(ROOTS_DASHBOARD, "/reports"),
        day: path(ROOTS_DASHBOARD, "/reports/day"),
        bus: path(ROOTS_DASHBOARD, "/reports/bus")
    },
    settings: path(ROOTS_DASHBOARD, "/settings")
}
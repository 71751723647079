const en = {
    all: "All",
    allowedTypeFile: "Allowed *.jpeg, *.jpg, *.png, *.gif",
    brand: "Brand",
    brandReq: "Must be for example \"Nissan\"",
    bus: "Bus",
    busCreated: "Bus created!",
    busCreatePage: "Bus: Create a new bus",
    busEditPage: "Bus: Edit bus",
    busList: 'Bus List',
    busStation: "Bus Station",
    buses: "Buses",
    busesOnRoute: "Buses on route",
    cancel: "Cancel",
    chooseLang: "Choose a language",
    circulationCard: "Circulation Card",
    circulationCardRequired: "Circulation Card expiration date is required",
    clear: "Clear",
    code: "Code",
    controlPoints: "Control points",
    create: "Create",
    createBus: "Create Bus",
    createDriver: "Create Driver",
    createNewBus: "Create a new bus",
    createNewDriver: "Create a new driver",
    control: "Control",
    date: "Date",
    day: "Day",
    delete: "Delete",
    deleteBus: "Delete bus",
    deleteBusMessage: "Are you sure want to delete the bus?",
    deleteBusesMessage: "Are you sure want to delete ",
    deleteDriver: "Delete driver",
    deleteDriverMessage: "Are you sure want to delete the driver?",
    deleteDriversMessage: "Are you sure wan to delete ",
    deleteRoute: "Delete route",
    demo: "Demo",
    description: "Description",
    disabled: "Disabled",
    dni: "DNI",
    documentNumberHint: "Must be exactly 8 digits ",
    documentNumberRequired: "Must be for example \"70482540\"",
    downloadBtn: "Download",
    driver: "Driver",
    driverCreated: "Driver created!",
    driverCreatePage: "Driver: Create a new driver",
    driverEditPage: "Driver: Edit driver",
    driverLicense: "Driver license",
    driverList: "Driver List",
    drivers: "Drivers",
    edit: "Edit",
    editBus: "Edit bus",
    editDriver: "Edit Driver",
    email: "Email",
    enabled: "Enabled",
    enableDriver: "Enable driver",
    errorUpdatingData: "Error updating data",
    filters: "Filters",
    forgotPassword: "Forgot password?",
    friday: "Friday",
    home: "Home",
    inputStringMaxSize: "Maximum 150 characters",
    insurance: "Insurance",
    dateRequired: "Must be for example \"23/05/2023\"",
    language: "Language",
    lap: "Lap",
    laps: "Laps",
    lastName: "Last name",
    lastNameRequired: "Must be for example \"Torres\" or \"Torres Silva\"",
    license: "License",
    licenseExpDate: "License",
    licenseExpDateRequired: "Expiry date is required",
    list: "List",
    liveTracking: "Live Tracking",
    login: "Login",
    logout: "Logout",
    management: "Management",
    map: "Map",
    maxSize: "max size of",
    model: "Model",
    modelReq: "Must be for example \"Civilian\"",
    monday: "Monday",
    name: "Name",
    nameRequired: "Must be for example \"Carlos\" or \"Carlos Enrique\"",
    newBus: "New Bus",
    newDriver: "New Driver",
    newRoute: "New Route",
    no: "No",
    noResults: "No Results",
    onlyLetters: "Must be only letters",
    options: "Options",
    others: "Others",
    owner: "Owner",
    ownerLastName: "Owner last name",
    ownerName: "Owner name",
    password: "Password",
    penalty: "Penalty",
    phone: "Phone",
    phoneNumberHint: "Must be exactly 9 digits",
    phoneNumberRequired: "Must be for example \"953654423\"",
    plate: "Plate",
    plateRequired: "Must be for example \"A2A588\"",
    profile: "Profile",
    registeredBus: "Already registered bus",
    registeredDriver: "Already registered driver",
    reportByBus: "Reports: By bus",
    reports: "Reports",
    role: "Role",
    roleRequired: "Must be for example \"Chofer\"",
    route: "Route",
    routeCreatePage: "Route: Create a new route",
    routeRequired: "Route is required",
    routes: "Routes",
    saturday: "Saturday",
    saveChanges: "Save Changes",
    searchBtn: "Search",
    search: "Search...",
    searchByBusOptions: "Search by plate",
    searchByDriverOptions: "Search by name, plate or phone number",
    selectPlateRequired: "Select a plate",
    selectRouteRequired: "Select a route",
    selected: "Selected",
    settings: "Settings",
    signIn: "Sign In",
    status: "Status",
    sunday: "Sunday",
    tuesday: "Tuesday",
    thursday: "Thursday",
    successCreateDriver: "Driver created!",
    unchangedBusData: "Unchanged bus data!",
    unchangedDriverData: "Unchanged driver data!",
    unknow: "Unknow",
    updatedBus: "Updated bus!",
    updatedDriver: "Updated driver!",
    updatePhoto: "Update photo",
    uploadControlPoints: "Upload control points",
    uploadFrequency: "Upload frequency",
    uploadPhoto: "Upload photo",
    uploadRoutes: "Upload routes",
    users: "Users",
    wednesday: "Wednesday",
    yearProduction: "Year",
    yearProductionRequired: "Must be for example \"2005\"",
    yes: "Yes",
    msgInvalidPass: "Invalid password",
    msgUserNotFound: "User not found",
    msgUserTempDisabled: "User temporally disabled since many failed attempts. Contact your administrator",
    msgErrorFound: "Ops, error found",
    labelSelectRoute: "Select a route",
    labelHour: 'Hour',
    labelPlate: 'Plate',
    labelStatus: 'Status',
    labelRoute: 'Route',
    msgReportDay: "Select the day and the route to download their report",
    msgDeleteRoute: "Are you sure want to delete the route?",
}

export default en
import './locales/i18n'
import 'simplebar/src/simplebar.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {HelmetProvider} from 'react-helmet-async'
import Router from './routes'
import ThemeProvider from './theme'
import ThemeLocalization from './locales'
import SnackbarProvider from './components/snackbar'
import {SettingsProvider, ThemeSettings} from './components/settings'
import {MotionLazyContainer} from './components/animate'
import ScrollToTop from './components/scroll-to-top'
import {getIconPath} from './utils/EnvUtils'
import {AuthProvider} from "./auth/AuthProvider"


export default function App() {
    const link = document.querySelector("link[rel~='icon']")
    // This effect runs once, after the first render
    useEffect(() => {
        link.href = getIconPath()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AuthProvider>
            <HelmetProvider>
                <SettingsProvider>
                    <BrowserRouter>
                        <ScrollToTop/>
                        <MotionLazyContainer>
                            <ThemeProvider>
                                <ThemeSettings>
                                    <ThemeLocalization>
                                        <SnackbarProvider>
                                            <Router/>
                                        </SnackbarProvider>
                                    </ThemeLocalization>
                                </ThemeSettings>
                            </ThemeProvider>
                        </MotionLazyContainer>
                    </BrowserRouter>
                </SettingsProvider>
            </HelmetProvider>
        </AuthProvider>
    )
}
